import React from 'react';
import styled from '@emotion/styled';
import {
  Link,
  devices,
  Content,
  BlueButtonBasic,
  Spacing,
  WhiteButtonLink,
} from '../common';
import Logo from '../../../static/assets/logo.png';
import { ExternalLink, PhoneIcon } from '../SVG';
import theme from '../../theme';
import MobileHeader from './MobileHeader';
import { Links as LinkType } from '../../@types/types';

const Header = () => {
  const links = [
    { name: 'Palvelut ja hinnasto', path: '/palvelut' },
    { name: 'Asiantuntijat', path: '/asiantuntijat' },
    { name: 'Aatoslaiseksi?', path: '/tyopaikat' },
    { name: 'Koulutukset', path: '/koulutukset' },
    { name: 'Työnohjaus', path: '/tyonohjaus' },
    { name: 'UKK', path: '/ukk' },
    { name: 'Blogi', path: '/blogi' },
    { name: 'Yhteys', path: '/yhteys' },
  ];

  return (
    <>
      <Container>
        <HeaderContainer align="center" justify="space-between" axis="x">
          <LeftWrapper>
            <Link to="/">
              <NavItem>
                <LogoImage src={Logo} alt="Aatos Klinikka" />
              </NavItem>
            </Link>
            <LinksContainer>
              {links.map(({ name, path }: LinkType, index) => (
                <NavItem key={index}>
                  <NavLink to={path} activeClassName="active">
                    {name}
                  </NavLink>
                </NavItem>
              ))}
            </LinksContainer>
          </LeftWrapper>
          <ExternalLinks>
            <NavLink to="/swedish-services">SV</NavLink>/
            <NavLink to="/english-services">EN</NavLink>
            <Spacing axis="x" amount="default" />
            <CustomBlueButton
              href="https://www.nordhealth.fi/fi/yritys/aatosklinikka"
              target="_blank"
            >
              Nettiajanvaraus
              <ExternalLinkIcon fill={theme.colors.white} />
            </CustomBlueButton>
            <WhiteButtonLink
              href="tel:+358103254540"
              style={{ textDecoration: 'none' }}
            >
              Soita &nbsp;
              <CustonPhoneIcon fill={theme.colors.darkBlue} />
            </WhiteButtonLink>
          </ExternalLinks>
        </HeaderContainer>
      </Container>

      <MobileHeader links={links} />
    </>
  );
};
export default Header;

const Container = styled.div`
  position: fixed;
  z-index: 4;
  background-color: ${(props) => props.theme.colors.white};
  width: 100%;
  box-shadow: 0px 3px 6px #0000000b;
`;

const HeaderContainer = styled(Content)`
  height: 100px;
  max-width: 1420px;

  a {
    text-decoration: none;
  }

  @media ${devices.laptopL} {
    width: 95% !important;
  }

  @media (max-width: 1320px) {
    display: none;
  }
`;

const NavItem = styled.li`
  list-style-type: none;
  margin-right: 20px;
  width: max-content;

  &:last-child {
    margin-right: 0px;
  }

  @media ${devices.mobileL} {
    margin-right: 45px;
  }
`;

const LinksContainer = styled.div`
  display: flex;
  margin: 20px;
`;

const NavLink = styled(Link)`
  text-decoration: none;
  color: ${(props) => props.theme.colors.black};
  ${(props) => props.theme.typography['body-small']}

  &.active {
    border-bottom: 3px solid ${(p) => p.theme.colors.blue};
    padding: 37px 0px;
    font-weight: bold;
  }
`;

const ExternalLinks = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0px !important;
`;

const LeftWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
`;

const ExternalLinkIcon = styled(ExternalLink)`
  margin-left: 10px;
`;

const CustomBlueButton = styled(BlueButtonBasic)`
  width: max-content;
  font-size: ${(props) => props.theme.sizing.default};
  font-weight: bold;

  @media ${devices.tablet} {
    margin-right: 0;
    align-self: center;
  }
`;

const LogoImage = styled.img`
  width: 180px;
  margin: 0px 0px 0px 0px;
`;

const CustonPhoneIcon = styled(PhoneIcon)`
  margin-left: 10px;
`;
