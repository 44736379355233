import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Link } from 'gatsby';
import { devices, Stack, BlueButtonBasic, Spacing } from '../common';
import Logo from '../../../static/assets/logo.png';
import { ExternalLink, Menu as MenuIcon, PhoneIcon } from '../SVG';
import theme from '../../theme';
import { Links as LinkType } from '../../@types/types';

type Props = {
  links: LinkType[];
};

const MobileHeader = ({ links }: Props) => {
  const [showMenu, setShowMenu] = useState(false);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };
  return (
    <Container>
      <MenuContainer>
        <Link to="/">
          <MobileImage src={Logo} alt="Aatos Klinikka" />
        </Link>
        <ButtonIconContainer>
          <CallButton
            href="tel:+358103254540"
            style={{ textDecoration: 'none', height: '44px', fontSize: '12px' }}
          >
            Soita 010 325 4540 &nbsp; &nbsp;
            <CustonPhoneIcon fill={theme.colors.white} width="18px" />
          </CallButton>
          <MobileCallButton
            href="tel:+358103254540"
            style={{ textDecoration: 'none', height: '44px', fontSize: '12px' }}
          >
            Soita &nbsp; &nbsp;
            <CustonPhoneIcon fill={theme.colors.white} width="18px" />
          </MobileCallButton>
          <Spacing axis="x" amount="default" />
          <IconContainer onClick={toggleMenu}>
            <MenuIcon />
          </IconContainer>
        </ButtonIconContainer>
      </MenuContainer>
      <MenuContent isOpen={showMenu} className={showMenu ? 'isOpen' : ''}>
        <UL>
          {links.map(({ path, name }: LinkType) => {
            return (
              <MobileNavItem key={path}>
                <NavLink
                  to={`${path}`}
                  activeClassName="active"
                  onClick={toggleMenu}
                >
                  {name}
                </NavLink>
              </MobileNavItem>
            );
          })}
          <MobileNavItem>
            <NavLink to="/swedish-services">SV</NavLink>/
            <NavLink to="/english-services">EN</NavLink>
          </MobileNavItem>
          <Spacing axis="y" amount="default" />
          <MobileExternalLinks axis="y" spacing="medium">
            <CustomBlueButton href="https://www.nordhealth.fi/fi/yritys/aatosklinikka">
              Nettiajanvaraus
              <ExternalLinkIcon fill={theme.colors.white} />
            </CustomBlueButton>
            <CustomBlueButton
              href="tel:+358103254540"
              style={{ textDecoration: 'none' }}
            >
              Soita 010 325 4540 &nbsp; &nbsp;
              <CustonPhoneIcon fill={`${theme.colors.white}`} />
            </CustomBlueButton>
          </MobileExternalLinks>
        </UL>
      </MenuContent>
    </Container>
  );
};

export default MobileHeader;

const Container = styled.div`
  display: none;
  flex-direction: column;
  transition: all 0.5s ease-in-out;
  position: fixed;
  z-index: 4;
  background-color: ${(props) => props.theme.colors.white};
  width: 100%;
  box-shadow: 0px 3px 6px #0000000b;

  @media (max-width: 1320px) {
    display: flex;
  }
`;

const MenuContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 75px;
  width: 90%;
  margin: auto;

  a {
    height: 100px;
  }

  @media ${devices.mobileXL} {
    width: 100%;
  }
`;

const MenuContent = styled.div<{ isOpen: boolean }>`
  z-index: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.colors.white};
  align-items: center;
  justify-content: center;
  position: fixed;
  opacity: 0;
  visibility: hidden;
  height: 0%;
  transition: opacity 0.75s, visibility 0.75s, height 0.75s;
  overflow: hidden;
  margin-top: 75px;
  box-shadow: 0px 3px 6px #0000000b;

  &.isOpen {
    opacity: 1;
    visibility: visible;
    height: 532px;
  }
`;

const MobileImage = styled.img`
  width: 250px;
  margin-top: 25px;
  margin-left: 10px;

  @media ${devices.laptopM} {
    width: 124px;
  }
`;

const UL = styled.ul`
  @media ${devices.tablet} {
    text-align: center;
  }
`;

const MobileNavItem = styled.li`
  line-height: 44px;
  margin-left: 40%;

  @media ${devices.laptopM} {
    margin-left: 20%;
  }

  @media ${devices.tablet} {
    margin-left: 0;
  }
`;

const NavLink = styled(Link)`
  text-decoration: none;
  font-size: ${(props) => props.theme.sizing.default};
  color: ${(props) => props.theme.colors.black};
  white-space: nowrap;

  &.active {
    border-bottom: 1px solid ${(p) => p.theme.colors.darkBlue};
  }
`;

const MobileExternalLinks = styled(Stack)`
  display: flex;
  flex-direction: column;
  margin-left: 40%;
  margin-top: 10px;

  @media ${devices.laptopM} {
    margin-left: 20%;
  }

  @media ${devices.tablet} {
    margin-left: 0;
  }
`;

const ExternalLinkIcon = styled(ExternalLink)`
  margin-left: 10px;
`;

const CustomBlueButton = styled(BlueButtonBasic)`
  width: max-content;
  min-width: 150px;
  font-size: ${(props) => props.theme.sizing.default};
  font-weight: bold;

  @media ${devices.laptopM} {
    margin: 0px;
  }

  @media ${devices.tablet} {
    margin-right: 0;
    align-self: center;
  }
`;

const IconContainer = styled.span`
  margin-right: ${(props) => props.theme.spacing.default};
`;

const CustonPhoneIcon = styled(PhoneIcon)`
  margin-left: 10px;
`;

const ButtonIconContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const CallButton = styled(BlueButtonBasic)`
  width: max-content;
  min-width: 150px;
  font-size: ${(props) => props.theme.sizing.default};
  font-weight: bold;

  @media ${devices.laptopM} {
    margin: 0px;
  }

  @media ${devices.tablet} {
    margin-right: 0;
    align-self: center;
  }

  @media ${devices.mobileS} {
    display: none;
  }
`;

const MobileCallButton = styled(BlueButtonBasic)`
  display: none;

  @media ${devices.mobileS} {
    display: flex;
    width: max-content;
    font-size: ${(props) => props.theme.sizing.default};
    font-weight: bold;
    margin-right: 0;
    align-self: center;
  }
`;
